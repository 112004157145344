<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.title,
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "",
            items: [],
            userinfo: [],
            devices: [],
            authstatus: false,
            billingmode: [
                {
                    value: 1,
                    label: this.$t("marketing.billing.normal"),
                },
                {
                    value: 2,
                    label: this.$t("marketing.billing.tiered"),
                },
            ],
            billingform: {
                status: true,
                model: 1,
            },
            form: {
                config: {},
                devices: ["all"],
            },
            systemconfig: {},
            isAllSelected: true,
        };
    },
    mounted() {
        this.title = this.$t("menuitems.marketing.billing.text");
        this.items = [
            {
                text: this.$t("menuitems.marketing.text"),
                href: "/",
            },
            {
                text: this.$t("menuitems.marketing.billing.text"),
                active: true,
            },
        ];
        this.userinfo = JSON.parse(localStorage.getItem("user"));
        //   this.page.title=this.$t('menuitems.devices.lists')
        this.getdevicelist();
        this.getauthstatus();
        this.getbillconfig();
    },
    methods: {
        getauthstatus() {
            var that = this;
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "getauthstatus",
                        app: "billing",
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.authstatus = response.data.lock;
                    that.systemconfig = JSON.parse(response.data.data);
                    console.log(that.systemconfig);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getdevicelist() {
            var that = this;
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "getdevicelist",
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.devices = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getbillconfig() {
            var that = this;
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "getbillingconfig",
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    if(response.data.status == 200){
                        that.form = response.data.data;
                    }
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        submitForm() {
            var that = this;
            that.$refs.form.validate((valid) => {
                if (valid) {
                    that.$axios
                        .post(
                            that.apiuri,
                            {
                                action: "savebillingconfig",
                                data: that.form,
                            },
                            {
                                headers: { Openid: that.userinfo.openid },
                            }
                        )
                        .then(function (response) {
                            if (response.data.status == 200) {
                                that.$message({
                                    message: that.$t("global.success"),
                                    type: "success",
                                });
                            } else {
                                that.$message({
                                    message: response.data.msg,
                                    type: response.data.status,
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        handleSelectChange(selected) {
            // 判断是否选中了 "all"
            if (selected.includes("all")) {
                this.isAllSelected = true;
                this.form.devices = ["all"]; // 保证只选择 all
            } else {
                this.isAllSelected = false;

                // 判断是否手动选择了所有设备（不包括 "all"）
                const deviceIds = this.devices.map((device) => device.ED_id);
                const allSelected = deviceIds.every((id) => selected.includes(id));

                if (allSelected) {
                    this.isAllSelected = true;
                    this.form.devices = ["all"]; // 切换到 all
                }
            }
        },
        addConfig() {
            this.form.config.push({ value: 0, discount: 0 });
        },
        removeConfig(index) {
            this.form.config.splice(index, 1);
        }
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row" v-if="authstatus">
                            <div class="col-12">
                                <el-form ref="form" :model="form" label-width="120px">
                                    <el-form-item :label="$t('marketing.billing.switch')">
                                        <el-switch v-model="form.status" :active-text="$t('marketing.billing.open')"
                                            :inactive-text="$t('marketing.billing.close')"></el-switch>
                                    </el-form-item>
                                    <el-form-item :label="$t('marketing.billing.model')">
                                        <el-select v-model="form.model" :placeholder="$t('marketing.billing.select')">
                                            <el-option v-for="item in billingmode" :key="item.value" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="$t('marketing.billing.supporteddevices')">
                                        <el-select v-model="form.devices" multiple
                                            :placeholder="$t('marketing.billing.select')" @change="handleSelectChange">
                                            <el-option value="all"
                                                :label="$t('marketing.billing.alldevices')"></el-option>
                                            <el-option v-for="item in devices" :key="item.ED_id" :label="item.name"
                                                :value="item.ED_id" :disabled="isAllSelected"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>
                                        <span>{{ $t('marketing.billing.value') }}</span>
                                        <span style="margin-left: 150px;">{{ $t('marketing.billing.discount') }}</span>
                                        <span style="margin-left: 120px;">{{ $t('marketing.billing.actions') }}</span>
                                    </el-form-item>
                                    <el-form-item v-for="(val, idx) in form.config"
                                        :label="$t('marketing.billing.stairs') + (idx + 1)" :key="idx">
                                        <div class="config-item">
                                            <el-input-number v-model="val.value" :label="$t('marketing.billing.value')"
                                                :min="0"></el-input-number>
                                            <el-input-number v-model="val.discount"
                                                :label="$t('marketing.billing.discount')" :min="0"></el-input-number>
                                            <el-button @click="removeConfig(idx)">{{ $t('marketing.billing.remove')
                                                }}</el-button>
                                        </div>
                                    </el-form-item>
                                    <el-form-item v-if="systemconfig.max > form.config.length">
                                        <el-button @click="addConfig">{{ $t('marketing.billing.addstairs')
                                            }}</el-button>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="submitForm">{{ $t('marketing.billing.save')
                                            }}</el-button>
                                    </el-form-item>
                                </el-form>
                                <!-- <el-form ref="form" :model="form" label-width="120px">
                                    <el-form-item v-for="device in devices" :key="device.ED_id" :label="device.name">
                                        <el-row v-for="(item, index) in form.config[device.ED_id]" :key="index"
                                            :gutter="20">
                                            <el-col :span="10">
                                                <el-form-item :label="'值 ' + (index + 1)">
                                                    <el-input v-model="item.value" placeholder="请输入值"></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="10">
                                                <el-form-item :label="'折扣 ' + (index + 1)">
                                                    <el-input v-model="item.discount" placeholder="请输入折扣"></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-form-item>
                                </el-form> -->
                            </div>

                        </div>
                        <el-empty v-else :description="$t('marketing.billing.notauth')"></el-empty>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style scoped>
.config-header {
    font-weight: bold;
    margin-bottom: 10px;
}

.config-item {
    margin-bottom: 10px;
}
</style>